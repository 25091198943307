import {useTranslation} from 'hooks/translations/useCustomTranslation';
import React from 'react';
import {useFormContext} from 'react-hook-form';

import {Box, Button, Typography} from 'components/basic-components';
import ContentWrapper from 'components/layout-components/ContentWrapper/ContentWrapper';

import CookieCheckbox from './CookieCheckbox';
import styles from './styles/DetailedModal.module.scss';

const DetailedModal = ({
  acceptAll,
  saveConsent,
  setDisplayedModal,
  dataPrivacyEssentials,
  dataPrivacyStatistics,
  dataPrivacyMarketing,
}) => {
  const {handleSubmit} = useFormContext();
  const {t} = useTranslation(['consent', 'common']);
  return (
    <ContentWrapper>
      <Box className={styles.modalDetailedContainer}>
        <Typography className={styles.headline} component="h2">
          {t('privacySettings')}
        </Typography>
        <Typography className={styles.detailedText}>{t('cookieManagerInfoText')}</Typography>
        <Box className={styles.buttonWrapper}>
          <Button variant="inverted" onClick={handleSubmit(acceptAll)}>
            {t('acceptAll')}
          </Button>
          <Button onClick={handleSubmit(saveConsent)}>{t('saveAndCloseSettings')}</Button>
        </Box>
        <button className={styles.goBackButton} onClick={() => setDisplayedModal('basic')}>
          <Typography className={styles.goBackButtonText}>{t('goBack')}</Typography>
        </button>
        <CookieCheckbox
          name={'essentials'}
          label={t('essentials (1)')}
          text={dataPrivacyEssentials?.informationText}
          cookieInformations={dataPrivacyEssentials?.cookieInformation}
          disabled
        />
        <CookieCheckbox
          name={'statistics'}
          label={t('statistics (2)')}
          text={dataPrivacyStatistics?.informationText}
          cookieInformations={dataPrivacyStatistics?.cookieInformation}
        />
        <CookieCheckbox
          name={'marketing'}
          label={t('marketing (3)')}
          text={dataPrivacyMarketing?.informationText}
          cookieInformations={dataPrivacyMarketing?.cookieInformation}
        />
        <CookieCheckbox
          label={t('externalMedia (4)')}
          text={t('externalMediaInfoText')}
          externalMedia
        />
      </Box>
    </ContentWrapper>
  );
};

export default DetailedModal;
