import {useTranslation} from 'hooks/translations/useCustomTranslation';
import React, {useState} from 'react';
import {Controller, useFormContext} from 'react-hook-form';

import {Checkbox, Typography} from '@mui/material';

import {Box} from 'components/basic-components';
import {CustomIcon} from 'components/basic-components/CustomIcon/CustomIcon';
import MarkdownContent from 'components/basic-components/MarkdownContent';

import DetailedCookieInformationTableContent from './DetailedCookieInformationTableContent';
import ExternalMediaContent from './ExternalMediaContent';
import styles from './styles/CookieCheckbox.module.scss';

interface CookieInformation {
  id: number;
  name?: string;
  cookieName?: string;
  cookieDuration?: string;
  purpose?: string;
  storageInformation?: string;
  others?: string;
  provider?: string;
}

const CookieCheckbox = ({
  cookieInformations,
  name,
  label,
  text,
  disabled = false,
  externalMedia = false,
}: {
  cookieInformations?: Array<CookieInformation>;
  name?: string;
  label: string;
  text: string;
  disabled?: true | false;
  externalMedia?: true | false;
}) => {
  const [showCookieInformation, setShowCookieInformation] = useState(false);
  const {control, watch, setValue} = useFormContext();
  const {t} = useTranslation('consent');

  const youtubeValue = watch('youtube');
  const mapsValue = watch('maps');

  const [isChecked, setIsChecked] = useState(youtubeValue && mapsValue ? true : false);

  const handleCheckboxExternalMedia = () => {
    const newValue = !isChecked;
    setIsChecked(newValue);
    setValue('maps', newValue);
    setValue('youtube', newValue);
  };

  return (
    <Box className={styles.cookieCheckbox}>
      <Box className={styles.inputWrapper}>
        <Typography className={styles.label}>{label}</Typography>
        {externalMedia ? (
          <Checkbox
            sx={{padding: 0, '& *': {color: 'black'}}}
            icon={<CustomIcon name="checkbox" className={styles.icon} />}
            checked={youtubeValue && mapsValue}
            onChange={handleCheckboxExternalMedia}
          />
        ) : (
          <Controller
            name={name}
            control={control}
            render={({field: {name, onChange, value}}) => {
              return (
                <Checkbox
                  sx={{padding: 0, '& *': {color: !disabled ? 'black' : null}}}
                  name={name}
                  onChange={onChange}
                  checked={value}
                  disabled={disabled}
                  icon={<CustomIcon name="checkbox" className={styles.icon} />}
                />
              );
            }}
          />
        )}
      </Box>
      <MarkdownContent className={styles.detailedText} content={text} />
      {showCookieInformation ? (
        <Box className={styles.tableWrapper}>
          <table style={{width: '100%', borderCollapse: 'collapse'}}>
            <colgroup>
              <col className={styles.tableColumn1} />
              <col className={styles.tableColumn2} />
            </colgroup>
            {externalMedia ? (
              <ExternalMediaContent />
            ) : (
              <DetailedCookieInformationTableContent cookieInformations={cookieInformations} />
            )}
          </table>
        </Box>
      ) : null}
      {!showCookieInformation ? (
        <button onClick={() => setShowCookieInformation(true)} className={styles.button}>
          <Typography className={styles.buttonText}>{t('displayCookieInfo')}</Typography>
        </button>
      ) : (
        <button onClick={() => setShowCookieInformation(false)} className={styles.button}>
          <Typography className={styles.buttonText}>{t('hideCookieInfo')}</Typography>
        </button>
      )}
    </Box>
  );
};

export default CookieCheckbox;
