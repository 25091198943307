import { NewFeaturesModalPropsContext } from "context/NewFeaturesModalContext";
import dynamic from "next/dynamic";
import { useContext } from "react";
import { useGetVarifyExperiments } from "utils/varify/useGetVarifyExperiments";
const NewFeaturesModalInner = dynamic(() => import('./NewFeaturesModalInner').then(comp => comp.NewFeaturesModalInner))


export const NewFeaturesModal = () => {
  const {experimentsStore: varifyExperiments, initialized} = useGetVarifyExperiments();

  const newFeaturesModalContextProps = useContext(NewFeaturesModalPropsContext);
  if(!newFeaturesModalContextProps) {
    return null;
  }
  const {
    newFeaturesModals: {title, newFeatureModalPages, clearCookiesHash},
  } = newFeaturesModalContextProps;
  const newFeaturesModals = newFeatureModalPages.filter(page => {
    if (
      page?.experimentId &&
      (varifyExperiments?.[page?.experimentId] &&
      !varifyExperiments?.[page?.experimentId]?.isVariation) || !varifyExperiments?.[page?.experimentId]
    ) {
      return false;
    } else {
      return true;
    }
  });
  if (!newFeaturesModals?.length) {
    return null;
  }
  return <NewFeaturesModalInner title={title} clearCookiesHash={clearCookiesHash} newFeaturesModals={newFeaturesModals} />;
};
