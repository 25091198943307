import {useTranslation} from 'hooks/translations/useCustomTranslation';
import React from 'react';
import {Controller, useFormContext} from 'react-hook-form';

import {Checkbox} from '@mui/material';

import {Box} from 'components/basic-components';
import {CustomIcon} from 'components/basic-components/CustomIcon/CustomIcon';

import styles from './styles/ExternalMediaContent.module.scss';

const ExternalMediaContent = () => {
  const {control} = useFormContext();
  const {t} = useTranslation(['consent', 'common']);

  return (
    <>
      <tbody style={{position: 'relative'}}>
        <Controller
          name={'youtube'}
          control={control}
          render={({field: {name, onChange, value}}) => {
            return (
              <Checkbox
                className={styles.checkbox}
                name={name}
                onChange={onChange}
                checked={value}
                icon={<CustomIcon name="checkbox" className={styles.icon} />}
              />
            );
          }}
        />
        <tr>
          <td className={styles.cookieParameter}>{t('name', {ns: 'common'})}</td>
          <td className={styles.cookieValues}>Youtube</td>
        </tr>
        <tr>
          <td className={styles.cookieParameter}>{t('provider')}</td>
          <td className={styles.cookieValues}>
            Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Ireland
          </td>
        </tr>
        <tr>
          <td className={styles.cookieParameter}>{t('purpose')}</td>
          <td className={styles.cookieValues}>{t('externalMediaYTPurpose')}</td>
        </tr>
      </tbody>
      <Box className={styles.spacer} />
      <tbody style={{position: 'relative'}}>
        <Controller
          name={'maps'}
          control={control}
          render={({field: {name, onChange, value}}) => {
            return (
              <Checkbox
                className={styles.checkbox}
                name={name}
                onChange={onChange}
                checked={value}
                icon={<CustomIcon name="checkbox" className={styles.icon} />}
              />
            );
          }}
        />
        <tr>
          <td className={styles.cookieParameter}>{t('name', {ns: 'common'})}</td>
          <td className={styles.cookieValues}>Google Maps</td>
        </tr>
        <tr>
          <td className={styles.cookieParameter}>{t('provider')}</td>
          <td className={styles.cookieValues}>
            Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Ireland
          </td>
        </tr>
        <tr>
          <td className={styles.cookieParameter}>{t('purpose')}</td>
          <td className={styles.cookieValues}>{t('externalMediaMapsPurpose')}</td>
        </tr>
      </tbody>
    </>
  );
};

export default ExternalMediaContent;
