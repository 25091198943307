import { NewFeaturesModal, } from 'lib/api/strapi';
import {createContext} from 'react';

export interface LocaleNewFeaturesModalProps {
  [key: string]: NewFeaturesModalProps;
}

export interface NewFeaturesModalProps {
  newFeaturesModals: NewFeaturesModal;
}

export const NewFeaturesModalPropsContext = createContext({
  newFeaturesModals: {},
} as NewFeaturesModalProps);
